import { create } from "zustand";

export interface WebcamOpenType {
  webcamOpen: boolean;
  setWebcamOpen: (sth: boolean) => void;
}

export const useWebcamOpenStore = create<WebcamOpenType>((set) => ({
  webcamOpen: false,
  setWebcamOpen: (sth: boolean) => {
    set({ webcamOpen: sth });
  },
}));
