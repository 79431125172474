import { CDN_ENABLED, CDN_URL } from "./constants";

// set NEXT_PUBLIC_CDN_DISABLED === true in your .local.env if you want to use your local public folder
const USE_CDN =
  CDN_ENABLED && !(process.env.NEXT_PUBLIC_CDN_DISABLED === "true");

export const getPublicAssetPath = (path: string): string => {
  // return path to asset in CDN or in local public folder

  if (!path) {
    throw new Error("Asset path is required");
  }

  // Handle special URL types
  if (path.match(/^(https?:\/\/|data:|blob:)/)) {
    return path;
  }

  // Handle protocol-relative URLs
  if (path.startsWith("//")) {
    return `https:${path}`;
  }

  // Normalize the path
  const normalizedPath = path.startsWith("/") ? path.slice(1) : path;

  // Use CDN if enabled
  if (USE_CDN) {
    return `${CDN_URL}public/${normalizedPath}`;
  }

  // Fallback to relative path
  return `/${normalizedPath}`;
};
